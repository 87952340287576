import React from "react";
import logo from "../../images/logo.jpg";
import PeymentCard from "./PeymentCard";
import styles from "./PeymentCards.module.css";

const PeymentCards = () => {
  return (
    <div className={styles.sectioncontainer}>
      <h2>دوره مورد نظر خود را انتخاب کنید</h2>

      <div className={styles.container}>
        <a
          className={styles.tel}
          href="https://ble.ir/heydaspackage"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <PeymentCard image={logo} name="کار با تریدینگ ویو" />
        </a>
        <a
          className={styles.tel}
          href="https://ble.ir/heydaspackage"
          target="_blank"
          rel="noreferrer"
        >
          <PeymentCard image={logo} name="انواع روند" />
        </a>

        <a
          className={styles.tel}
          href="https://ble.ir/heydaspackage"
          target="_blank"
          rel="noreferrer"
        >
          <PeymentCard image={logo} name="مووینگ اورج ها" linkto="/DogePey" />
        </a>
        <a
          className={styles.tel}
          href="https://ble.ir/heydaspackage"
          target="_blank"
          rel="noreferrer"
        >
          <PeymentCard image={logo} name="فیبوناچی" linkto="/BcashPey" />
        </a>
        <a
          className={styles.tel}
          href="https://ble.ir/heydaspackage"
          target="_blank"
          rel="noreferrer"
        >
          <PeymentCard image={logo} name="اندیکاتور" linkto="/telegramPey" />
        </a>
        <a
          className={styles.tel}
          href="https://ble.ir/heydaspackage"
          target="_blank"
          rel="noreferrer"
        >
          <PeymentCard image={logo} name="حمایت و مقاومت" />
        </a>
      </div>
    </div>
  );
};

export default PeymentCards;
