import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Header from "./components/Layout/Header";
import Hero from "./components/Layout/Hero";
import PeymentCards from "./components/Card/PeymentCards";
import Footer from "./components/Layout/Footer";

import "./App.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="App">
        <Header />
        <Hero />
        <PeymentCards />
        <Footer />
      </div>
    ),
  },
]);

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
