import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={styles.container}>
      <p className={styles["first-paragraph"]}>
        {" "}
        در صورت بروز هر گونه مشکل یا سوال میتوانید با ما در ارتباط باشید.📩
      </p>

      <div className={styles["lists-container"]}>
        <ul>
          <h4>درباره هیداس پکیج</h4>
          <p>
            تیم هیداس با مدیریت فرهاد فیروزی مدرس تمام دوره های تحلیل از مبتدی
            تا پیشرفته با سابقه بیش از ده سال در بازارهای مالی چندین سال است که
            فعالیت خود را آغاز کرده است. در فروشگاه اینترنتی هیداس پکیج میتوانید
            تمامی پکیج های آموزشی را تهیه کنید و به همراه پشتیبانی از آموزش خود
            لذت ببرید.
          </p>
        </ul>

        <ul className={styles["contact-list"]}>
          <h4>ارتباط با ما</h4>
          <li>شماره تماس پشتیبانی 09122722713</li>
          <li>ایمیل heydaspackage@gmail.com</li>
          <li>
            <a
              href="https://Ble.ir/farhadfirouzi"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              پشتیبانی در پیامرسان بله{" "}
            </a>
          </li>
        </ul>

        <a
          referrerpolicy="origin"
          rel="noreferrer"
          href="https://trustseal.enamad.ir/?id=415790&Code=LWC1Ofrvi3YSpB3tYKMgoTPhJnSt1XPE"
          target="_blank"
        >
          <img
            referrerpolicy="origin"
            src="https://trustseal.enamad.ir/logo.aspx?id=415790&Code=LWC1Ofrvi3YSpB3tYKMgoTPhJnSt1XPE"
            alt=""
            Code="LWC1Ofrvi3YSpB3tYKMgoTPhJnSt1XPE"
          />
        </a>
      </div>

      <p className={styles.footerText}>
        کلیه حقوق مادی و معنوی برای فرهاد فیروزی محفوظ میباشد و هرگونه کپی
        برداری شامل پیگرد قانونی میباشد.
      </p>
    </div>
  );
};

export default Footer;
