import React from "react";
import styles from "./Header.module.css";
import logo from "../../images/logo.jpg";

const Header = () => {
  return (
    <div className={styles.container}>
      <span className={styles.text}>به صفحه ما خوش آمدید</span>
      <div className={styles.logo_container}>
        <h2>Heydas Package</h2>
        <img src={logo} className={styles.logo} alt="logo" />
      </div>
    </div>
  );
};

export default Header;
