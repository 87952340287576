import React from "react";
import heroImage from "../../images/hero.jpg";
import styles from "./Hero.module.css";

const Hero = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textcontainer}>
        <h1 className={styles.title}>هیداس پکیج </h1>
        <h3 className={styles.detailtext}>
          با دوره های پکیج و پشتیبانی ما در بازار های مالی به سود مستمر و عالی
          برسید.
        </h3>

        <p>
          هیداس پکیج دوره های عالی در زمینه تحلیل تکنیکال بازار های مالی از جمله
          بورس؛فارکس و ارزهای دیجیتال است.
        </p>
      </div>
      <div className={styles.imagecontainer}>
        <img src={heroImage} alt="Internet" className={styles.image} />
      </div>
    </div>
  );
};

export default Hero;
