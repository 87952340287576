import React from "react";
import styles from "./PeymentCard.module.css";

const PeymentCard = (props) => {
  return (
    <div className={styles.container}>
      <img src={props.image} alt="Payment gateway" />
      <p>دوره ی {props.name}</p>
    </div>
  );
};

export default PeymentCard;
